.bulbWrapper {
    position: absolute;
    top: -36px;
    left: -26px;
    background: transparent;
    user-select: none;
    transform: scale(0.8);
    >.arrowWrapper {
        transition: all .5s;
        width: 100%;
        position: absolute;
        top: 130px;
        left: 240px;
        transform: scale(0.7);
        >.arrowText {
            position: absolute;
            left: 70px;
            top: 8px;
            font-family: 'Amatic SC', cursive;
            font-weight: 400;
            font-size: 54px;
            letter-spacing: 3px;
            
        }
        >.bulbArrow {
            top: 30px;
            position: absolute;
            transform: rotate(-45deg) scale(0.8);
        }
    }
    >.wire {
        z-index: -1;
        position: absolute;
        top: 0;
        left: -1px;
        pointer-events: none;
    }
    >.bulb {
        padding-top: 115px;
        padding-left: 65px;
        pointer-events: none;
        user-select: none;
    }
    >.lights {
        z-index: -2;
        position: absolute;
        top: 215px;
        left: 152px;
        width: 0.2px;
        height: 0.2px;
        border-radius: 100px;
        transition: all 1s;
        opacity: 1;
    }
    >.ellipses {
        position: absolute;
        pointer-events: none;
        z-index: -1;
        top: 9.4em;
        left: 5.7em;
    }
}
@media (max-width: 767px) {
    .bulbWrapper {
        top: -70px;
        left: -60px;
        transform: scale(0.6);
    }
}
@media (max-width: 376px) {
    .bulbWrapper {
        top: -100px;
        left: -80px;
        transform: scale(0.4);
    }
}


