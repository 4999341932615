body {
  background: #0A0A0A;
  color: #fff;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

body, html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Montserrat',
    monospace;
}

html {
  scroll-behavior: smooth;
}

input {outline:none;}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Slab', sans-serif;
  font-size: 18px;
  font-weight: 200;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  width: 100%;
}

img,
i {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* a[target='_blank'] {
  align-items: center;
  display: flex;
}
a[target='_blank']:after {
  content: url(./img/exlink.svg);
} */

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (max-width: 767px) {
  @-moz-document url-prefix() {
    .App {
      height: 100vh; 
      overflow-y: scroll;
    }
  }
}



@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    transition-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Based styles end. */
@media (min-width: 767px) {
  #leftCheck {
    margin-right: 1px;
    border-radius: 50px 0 0 50px;
  }
  #rightCheck {
    margin-left: 1px;
    border-radius: 0 50px 50px 0;
  }
}