.carouselWrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px;
    min-height: 100vh;
}

