.mainScreenWrapper {
    height: 100vh;
    margin: 0 auto;
    .headerWrapper {
        position: relative;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        align-items: center;
        padding-top: 20px;
        margin-bottom: 50px;
        transition: all 1.5s;
    }
    .menu {
        font-weight: 400;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: right;
        >li {
            margin-right: 80px;
            display: inline-block;
            >a {
                display: block;
            }
        }   
    }
    .nav-button {
        padding: 12px 26px;
        font-weight: 500;
        background: #fff;
        color: #000;
        border-radius: 50px;
    }
    .mainWrapper {
        width: 95%;
        margin: 0 auto;
        user-select: none;
        >.mainLeft {
            font-family: 'Montserrat', sans-serif;
            padding-top: 10em;
            width: 50%;
            >h1 {
                text-shadow: rgb(0, 0, 0, 0.3) 0 0 22px;
                line-height: 1.2;
                position: relative;
                z-index: 50;
                font-size: 50px;
                margin-top: 0px;
                margin-bottom: 10px;
            }
            >p {
                position: relative;
                z-index: 50;
            }
        }
        @media (max-width: 376px) {
            >.mainLeft {
                padding-top: 4rem;
                margin: 0 auto;
                width: 95%;
                >h1 {
                    font-size: 24px;
                    text-align: center;
                }
                >p {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
        @media ((min-width: 377px) and (max-width: 767px)) {
            >.mainLeft {
                padding-top: 8rem;
                margin: 0 auto;
                width: 90%;
                z-index: 200;
                >h1 {
                    font-size: 32px;
                    text-align: center;
                    margin-bottom: 6px;
                }
                >p {
                    z-index: 100;
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
        @media ((min-width: 481px) and (max-width: 1024px)) {
            >.mainLeft {
                padding-top: 8rem;
                margin: 0 auto;
                >h1 {
                    margin-top: 30px;
                    font-size: 36px;
                    text-align: center;
                }
                >p {
                    text-align: center;
                }
            }
        }
        >.mainRight {
            float: right;
            display: flex;
            margin-top: 60px;
            >img {
                user-select: none;
                width: 80vh;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: -5;
            }
            >.mainImage {
                transition: all 1.5s;
            }
        }
        @media (max-width: 480px) {
            >.mainRight {
                >img {
                    width: 22em;
                    // margin-bottom: 30px;
                }
                >.mainImage {
                    bottom: 0;
                    right: 0;
                }
            }
        }
        @media (min-width: 1024px) {
            >.mainLeft {
                >h1 {
                    font-size: 56px;
                }
                >p {
                    font-size: 18px;
                }
            }
        }
        @media (min-width: 1560px) {
            >.mainLeft {
                >h1 {
                    font-size: 72px;
                }
                >p {
                    font-size: 28px;
                }
            }
        } 
    }
}
@media (max-width: 376px) {
    .mainScreenWrapper {
        height: 42em;
        margin-bottom: 5px;
        >.mainWrapper {
            >.mainRight {
                >.mainImage {
                    width: 17em;
                }
            }
        }
    }
}
@media ((min-width: 377px) and (max-width: 767px)) {
    .mainScreenWrapper {
        height: 38em;
        margin-bottom: 60px;
    }
}