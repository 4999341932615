.revOne {
    opacity: 0;
}
.revTwo {
    opacity: 0;
}
.revThree {
    opacity: 0;
}
.revFour {
    opacity: 0;
}
.revFive {
    opacity: 1;
    transform: translateY(-35px);
}
.revSix {
    opacity: 1;
    transform: translateY(-25px);
}
.revSev {
    opacity: 1;
    transform: translateY(-35px);
}
.revEight {
    opacity: 1;
    transform: translateY(-50px);
}
.revNine {
    opacity: 1;
    transform: translateY(-30px) scale(0.7);
}
.revTen {
    opacity: 1;
    transform: translateY(-40px) translateX(-18px) scale(0.6);
}
._1uQwZ {
    opacity: 0;
}

@media (max-width: 767px) {
    .revFive {
        transform: translateY(0px) translateX(70px) scale(0.8);
    }
    .revSix {
        transform: translateY(0px) translateX(70px) scale(0.8);
    }
    .revSev {
        opacity: 1;
        transform: translateY(0px) translateX(70px) scale(0.8);
    }
    .revEight {
        opacity: 1;
        transform: translateY(0px) translateX(70px) scale(0.8);
    }
    .revNine {
        opacity: 1;
        transform: translateY(0px) translateX(70px) scale(0.8);
    }
    .revTen {
        opacity: 1;
        transform: translateY(0px) translateX(70px) scale(0.8);
    }
}
@media (min-width: 768px) {
    .revFive {
        transform: scale(0.5);
    }
    .revSix {
        transform: scale(0.5);
    }
    .revSev {
        opacity: 1;
        transform: scale(0.5);
    }
    .revEight {
        opacity: 1;
        transform: scale(0.5);
    }
}