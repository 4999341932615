/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 20px;
    right: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
    transition: all 0.3s;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #373a47;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px;
    width: 30px;
    right: 22px;
    top: 22px;
    z-index: 0;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(30px);
    padding-top: 2.4em;
    font-size: 48px;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    padding-bottom: 0.5em;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    padding: 15px 20px;
    margin-bottom: 8px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50px;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
.formBtn {
  background: #fff;
  color: #000;
}