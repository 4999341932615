.methodsWrapper {
    font-family: 'Montserrat', sans-serif;
    background: #fff;
    color: #000;
    margin-bottom: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    >.methodsContent {
        text-align: center;
        width: 70%;
        margin: 0 auto;
        >h2 {
            font-weight: 400;
            margin-bottom: 20px;
        }
        >.methods {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            >.methodEl {
                padding: 10px 70px;
                margin: 6px;
                background: #F3F3F3;
                border-radius: 50px;
                font-weight: 500;
                font-size: 16px;
                flex-grow: 1;
                overflow: hidden;
            }
            @media (max-width: 767px) {
                >.methodEl {
                    font-size: 16px;
                    padding: 12px 12px;
                    margin: 4px;
                }
            }
        }
    }
    @media (max-width: 767px) {
        >.methodsContent {
            width: 90%;
        }
    }
}