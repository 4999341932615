
.revOne {
    opacity: 1;
    transform: scale(0.9);
}
.revTwo {
    opacity: 1;
    transform: scale(0.9);
}
.revThree {
    opacity: 1;
    transform: scale(0.9);
}
.revFour {
    opacity: 1;
    transform: scale(0.9);
}
.revFive {
    opacity: 1;
}
.revSix {
    opacity: 0;
}
.revSev {
    opacity: 0;
}
.revEight {
    opacity: 0;
}