.reviewsWrapper {
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto;
    background-image: url('./media/phone_bg.webp');
    background-size: 50vh;
    width: 70%;
    height: 100vh;
    background-position: top;
    background-repeat: no-repeat;
}
.revHeader {
    margin: 0 auto;
    width: 70%;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    >h2 {
        font-weight: 400;
    }
}

@media (max-width: 767px) {
    .reviewsWrapper {
        width: 100%;
    }
    .revHeader {
        width: 90%;
    }
}