.degreesWrapper {
    padding: 40px 0;
    margin-bottom: 50px;
    background: #fff;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    >.degreesHead {
        text-align: center;
        padding-bottom: 20px;
        >h2 {
            font-weight: 400;
        }
    }
    >.degreesGrid { 
        max-width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        >.gridEl {
            padding: 20px;
            border: 1px solid #dadada;
            font-size: 15px;
        }
        >.gridEl:nth-child(-n+3) {
            border-top: 0;
        }
        >.gridEl:nth-child(3n+1) {
            border-left: 0;
        }
        >.gridEl:nth-child(3n) {
            border-right: 0;
        }
        >.gridEl:nth-child(n+7) {
            border-bottom: 0;
        }
        
    }
}
@media (max-width: 767px) {
    .degreesWrapper {
        padding: 20px 0;
        margin-bottom: 50px;
        >.degreesGrid { 
            max-width: 90%;
            height: 60vh;
            margin: 0 auto;
            overflow-y: scroll;
            grid-template-rows: repeat(9, 1fr);
            grid-template-columns: 1fr;
            >.gridEl {
                border-bottom: 2px solid #dadada;
                border-top: 0;
                border-left: 0;
                border-right: 0;
            }
            >.gridEl:nth-child(n+7) {
                border-bottom: 2px solid #dadada;
            }
            >.gridEl:nth-last-child(-n+1) {
                border-bottom: 0;
            }
        }
    }
}