.formatsWrapper {
    font-family: 'Montserrat', sans-serif;
    background: #0A0A0A;
    color: #fff;
    margin-bottom: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    >.formats {
        align-items: baseline;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        width: 70%;
        margin: 0 auto;
        >.formatsHead {
            max-width: 35%;
            >h2 {
                line-height: 1.2;
                text-align: left;
                font-size: 46px;
                font-weight: 500;
                margin-bottom: 40px;
            }
        }
        @media (max-width: 767px) {
            >.formatsHead {
                max-width: 90%;
                margin: 0 auto;
                >h2 {
                    text-align: center;
                    margin: 0 auto;
                    min-width: 90%;
                    font-size: 28px;
                    font-weight: 500;
                    margin-bottom: 45px;
                }
            }
        }
        >.formatsList {
            list-style: disc;
            text-align: left;
            padding-left: 90px;
            width: 100%;
            margin: 0 auto;
            >.format {
                margin-bottom: 12px;
                font-size: 18px;
                padding-left: 8px;
            }
            @media (max-width: 767px) {
                >.format {
                    font-size: 18px;
                    margin-bottom: 32px;
                }
            }
        }
        @media (max-width: 767px) {
            >.formatsList {
                padding-left: 40px;
            }
        }
    }
    @media (max-width: 767px) {
        >.formats {
            display: inline;
        }
    }
}
@media (max-width: 767px) {
    .formatsWrapper {
        margin: 0 auto;
        width: 95%;
    }
}