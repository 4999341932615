.principlesWrapper {
    font-family: 'Montserrat', sans-serif;
    background: #fff;
    color: #000;
    display: flex;
    width: 100%;
    max-height: 70%;
    >.princImg {
        height: 70%;
        >img {
            width: 100%;
        }
    }
    >.princCont {
        max-width: 60%;
        max-height: 90%;
        overflow-y: scroll;
        background: #fff;
        padding: 30px 40px;
        margin-bottom: 0px;
        >.contHeader {
            font-size: 24px;
            padding-bottom: 10px;
        }
        >.contBody {
            >.contInst {
                height: 100%;
                padding: 20px 0;
                border-bottom: 2px solid #dadada;
                font-size: 22px;
            }
            @media (max-width: 1366px) {
                >.contInst {
                    font-size: 20px;
                }
            }
            @media (max-width: 1100px) {
                >.contInst {
                    font-size: 18px;
                }
            }
            >.contInst:last-child {
                border-bottom: 0;
                
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1215px) {
        >.princCont {
            min-width: 30%;
            max-width: 50%;
            min-height: 40vh;
            max-height: 70vh;
        }
    }
}
@media (max-width: 767px) {
    .principlesWrapper {
        width: 100%;
        height: 100%;
        background: #fff;
        display: inline;
        >.princCont {
            max-height: 100vh;
            max-width: 50%;
            text-align: center;
            margin-bottom: 20px;
            padding-top: 40px;
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
            overflow-y: hidden;
            >.contHeader {
                font-size: 26px;
                padding-bottom: 22px;
                border-bottom: 2px solid #dadada;
            }
            >.contBody {
                >.contInst {
                    padding: 22px 0;
                    font-size: 18px;
                }
            }
        }
        >.princImg {
            min-width: 100%;
            max-width: 100%;
            >img {
                width: 100%;
            }
        }
    }
}