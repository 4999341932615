.footerWrapper {
    font-family: 'Montserrat', sans-serif;
    background: #fff;
    color: #000;
    padding-bottom: 80px;
    padding-top: 60px;
    >.footerContent {
        text-align: center;
        width: 70%;
        margin: 0 auto;
        >.footerHeader {
            margin-bottom: 30px;
        }
        >.footerBtn {
            border: 0;
            border-radius: 50px;
            padding: 12px 60px;
            background: #232323;
            color: #fff;
            font-weight: 500;
        }
    }
}