.contactsModalWrapper {
    position: absolute;
    color: #fff;
    z-index: 998;
    position: fixed;
    font-family: 'Montserrat', sans-serif;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.9);
    backdrop-filter: blur(8px);
    transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
    font-size: 22px;
    >.crossIcon {
        position: absolute;
        top: 30px;
        right: 30px;
        >img {
            height: 25px;
            width: 25px;
            opacity: 0.8;
        }
    }
    @media (max-width: 767px) {
        >.crossIcon {
            visibility: hidden;
        }
    }
    >.contactsModalContent {
        z-index: 1000;
        position: absolute;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 70%;
        margin: auto;
        text-align: center;
        >.contactsWrapper {
            margin: 0 auto;
            display: inline-block;
            >li {
                margin-bottom: 20px;
                >a {
                    border-radius: 50px;
                    display: block;
                    width: 100%;
                    padding: 12px 34px;
                    border: 1px solid #fff;
                }
            }
            >li:nth-child(-n+1) {
                >a {
                    background: #fff;
                    color: #000;
                }
            }
            // >.contactsEl:last-child::before {
            //     content: url("./facebook-f.svg");
            //     width: 20px;
            //     height: 20px;
            // }
        }
        @media (max-width: 767px) {
            >.contactsWrapper {
                >li {
                    margin-bottom: 12px;
                    >a {
                        border-radius: 50px;
                        display: block;
                        width: 100%;
                        padding: 12px 30px;
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        >.contactsModalContent {
            width: 90%;
        }
    }
}
@media (max-width: 767px) {
    .contactsModalWrapper {
        font-size: 16px;

    }
}
