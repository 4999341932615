._1Lxpd {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0;
}

._1Lxpd span {
    z-index: 10;
    margin: 0 auto;
    display: flex;
}

._3tZKZ {
    list-style: none;
    padding: 0;
    position: relative;

}

._215Bn {
    width: 50%;
    height: 70vh;
    margin: 20px;
}
@media (max-width: 767px) {
    ._215Bn {
        width: 70%;
    }
}

._2ocTZ {
    color: cornflowerblue;
    font-size: 2em;
    padding: 5px;
}

._2YVEG {
    color: cornflowerblue;
    font-size: 2em;
    padding: 5px;
}

._3kMpo {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(80px, 1fr));
    grid-gap: 0px;
}

._3kMpo div {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
}


._3kMpo img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}

._xsi02 {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    opacity: 0; 
    position: absolute;
    transform: scale(1) translateX(40px);
}

._xsi02 img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    overflow: hidden;
}

._xsi02._3sodH {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 3;
}

._xsi02._1uQwZ {
    opacity: 1;
    z-index: 2;
    transform: scale(0.9) translateX(70px);
    filter: brightness(70%);
}

._xsi02._1uQwZ:nth-child(n+5) {
    opacity: 0;
}

._xsi02._KqFRp:nth-child(n+4) {
    opacity: 0;
}

._xsi02:nth-child(n+5) {
    transform: translate(-100px);
}

._xsi02._KqFRp:nth-child(n+4) {
    transform: translateX(-180px);
    z-index: 0;
}

._xsi02._KqFRp {
    transform: scale(0.85) translateX(-80px);
    z-index: 1;
    opacity: 1;
    filter: brightness(60%);
}

._xsi02._3hvs3 {
    transform: scale(1) translateX(0px);
    z-index: 1;
    opacity: 0;
}

._xsi02._1HDdD {
    transform: scale(1) translateX(0px);
    z-index: 1;
    opacity: 0;
}

