.aboutWrapper {
    font-family: 'Montserrat', sans-serif;
    background: #0A0A0A;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    z-index: 90;
    >.togglerWrapper {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 15px;
        >.toggleButton {
            margin-bottom: 20px;
            width: 100%;
            font-weight: 700;
            font-size: 18px;
            padding: 20px 100px;
            border: none;
            transition: all 0.4s;
        }
        @media (max-width: 1366px) {
            >.toggleButton {
                padding: 15px 60px;
            }
        }
        @media (max-width: 480px) {
            >.toggleButton {
                padding: 10px 4px;
                font-size: 12px;
            }
        }
        @media (max-width: 1024px) {
            >.toggleButton {
                padding: 15px 20px;
            }
        }
        >.toggleButton:nth-child(1) {
            border-radius: 50px 0 0  50px;
            border-right: 4px solid #fff;
        }
        >.toggleButton:nth-child(2) {
            border-radius: 0;
        }
        >.toggleButton:nth-child(3) {
            border-radius: 0 50px 50px 0;
            border-left: 4px solid #fff;
        }
    }
    @media (max-width: 1024px) {
        >.togglerWrapper {
            width: 90%;
        }
    }
    >.listWrapper {
        background: linear-gradient(#1E1E1E,#1E1E1E) no-repeat center/2px 97%;
        width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
        transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
        margin-bottom: 30px;
    }
    @media (max-width: 1024px) { 
        >.listWrapper {
            width: 90%;
        }
    }
    @media (max-width: 767px) { 
        >.listWrapper {
            background: 0;
            grid-template-columns: repeat(1, 1fr);
            overflow-y: scroll;
            height: 60vh;
        }
    }

    >.talkBtn {
        margin: 0 auto;
        border: 0;
        display: flex;
        background-color: #fff;
        color: #000;
        border-radius: 50px;
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        >.talkBtnIn {
            font-weight: 700;
            display: flex;
            display: flex;
            align-items: center;
            transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
            padding: 12px 14px;
            >.cartImg {
                padding-right: 7px;
                padding-bottom: 2px;
            }
            >.activeItemsCount {
                font-weight: 500;
                font-size: 24px;
                padding-right: 9px;
                vertical-align: middle;
                border-right: 2px solid #DBDBDB;
            }
            >.talk {
                padding-left: 15px;
                font-size: 18px;
            }
        }
    }
    >.talkBtnEnter {
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    >.talkBtnEnterActive {
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    >.talkBtnExit {
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    // n ms
    >.talkBtnExitActive {
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    >.aboutMeWrapper {
        width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        >div{padding-bottom: 20px; padding-top: 20px; >h1 {font-weight: 800;} }
        .upuWrapper { 
            display: flex; 
            grid-area: 1 / 1 / 2 / 3; 
            border-bottom: 2px solid #232323;
            >h1 {margin-right: 50px;}
        }
        .yWrapper { 
            border-right: 2px solid #232323; 
            border-bottom: 2px solid #232323; 
            grid-area: 2 / 1 / 3 / 2; 
        }
        .hWrapper { 
            grid-area: 2 / 2 / 3 / 3; 
            padding-left: 50px;
            border-bottom: 2px solid #232323;
        }
        .cWrapper { 
            border-right: 2px solid #232323; 
            border-bottom: 2px solid #232323; 
            grid-area: 3 / 1 / 4 / 2; 
        }
        .ycWrapper { 
            grid-area: 3 / 2 / 4 / 3; 
            padding-left: 50px;
            border-bottom: 2px solid #232323;
        }
        .sWrapper { 
            border-right: 2px solid #232323; 
            grid-area: 4 / 1 / 5 / 2; 
        }
        .bWrapper {
            position: relative;
            grid-area: 4 / 2 / 5 / 3; 
            padding-left: 50px;
            >.moreButton {
                position: absolute;
                top: 30%;
                background: #fff;
                color: #000;
                font-weight: 600;
                border: none;
                border-radius: 50px;
                padding: 16px 40px;
            }
            >.moreButton::after {
                padding-left: 30px;
                content: url('./btnArrow.svg');
                display: inline-flex;
                background-size: 10px 10px;
            }
        }
    }
    @media (max-width: 1024px) {
        >.aboutMeWrapper {
            width: 90%;
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: repeat(7, 1fr); 
            grid-column-gap: 0px;
            grid-row-gap: 0px; 
            text-align: center;
            height: 70vh;
            overflow-y: scroll;
            >div{padding-bottom: 30px; padding-top: 30px; height: 100%; align-items: center; 
                >h1 {font-weight: 800; margin: 0; padding: 0;} 
            }
            >.upuWrapper { 
                >h1 {
                    margin-right: 0;
                }
                display: inline;
                grid-area: 1 / 1 / 2 / 2; 
            }
            >.yWrapper { 
                grid-area: 2 / 1 / 3 / 2;
                border-right: 0;
            }
            >.hWrapper { 
                grid-area: 3 / 1 / 4 / 2;
                padding-left: 0;
            }
            >.cWrapper { 
                grid-area: 4 / 1 / 5 / 2;
                border-right: 0;
            }
            >.ycWrapper { 
                grid-area: 5 / 1 / 6 / 2;
                padding-left: 0;
            }
            >.sWrapper { 
                grid-area: 6 / 1 / 7 / 2;
                border-right: 0;
            }
            >.bWrapper {
                padding-left: 0;
                margin: 0 auto;
                grid-area: 7 / 1 / 8 / 2;
                >.moreButton {
                    position: relative;
                    top: 0;
                    background: #fff;
                    font-weight: 600;
                    border: none;
                    border-radius: 50px;
                    padding: 16px 40px;
                }
                >.moreButton::after {
                    padding-left: 30px;
                    content: url('./btnArrow.svg');
                    display: inline-flex;
                    background-size: 10px 10px;
                }
            }
        }
    }
    >.usWrapper {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        >p {
            width: 100%;
            flex: 50%;
            padding: 30px;
            font-size: 16px;
            font-weight: 400;
        }
        >p:nth-child(1) {
            border-right: 1px solid #232323;
            border-bottom: 1px solid #232323;
        }
        >p:nth-child(2) {
            border-left: 1px solid #232323;
            border-bottom: 1px solid #232323;
        }
        >p:nth-child(3) {
            border-right: 1px solid #232323;
            border-top: 1px solid #232323;
        }
        >p:nth-child(4) {
            border-left: 1px solid #232323;
            border-top: 1px solid #232323;
        }
    }
    @media (max-width: 1024px) {
        >.usWrapper {
            width: 90%;
            >p {
                flex: 100%;
                text-align: center;
            }
            >p:nth-child(1) {
                border-bottom: 1px solid #232323;
                border-left: 0;
                border-right: 0;
                border-top: 0;
            }
            >p:nth-child(2) {
                border-bottom: 1px solid #232323;
                border-top: 1px solid #232323;
                border-left: 0;
                border-right: 0;
            }
            >p:nth-child(3) {
                border-bottom: 1px solid #232323;
                border-top: 1px solid #232323;
                border-left: 0;
                border-right: 0;
            }
            >p:nth-child(4) {
                border-top: 1px solid #232323;
                border-bottom: 0;
                border-left: 0;
                border-right: 0;
            }
        }
    }
}
@media (max-width: 767px) {
    .aboutWrapper {
        padding-top: 0;
        margin-top: 0;
        padding-bottom: 20px;
    }
}