.check {
    height: 60px;
    margin-bottom: 14px;
    overflow: hidden;
    float: left;
    >label {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        cursor: none;
        user-select: none;
        float: left;
        >span {
            width: 100%;
            transition: all 0.6s;
            padding: 15px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            text-align: left;
            vertical-align: middle;
            >img {
                border: 0;
                color: #000;
                pointer-events: none;
                transition: all 0.6s;
                width: 20px;
                height: 20px;
                align-items: start;
            }
        }
        >input {
            position: absolute;
            display: none;
            color: #fff !important;
        }
        >input:checked + span{
            background-color: #fff;
            color: #000;
        }
        >input:checked + span>img {
            filter: invert(100%);
            transform: rotate(-45deg);
        }
    }
}
@media (max-width: 767px) {
    .check {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        margin-bottom: 6px;
    }
}