.modalWrapper {
    >.crossIcon {
        position: absolute;
        top: 30px;
        right: 30px;
        >img {
            height: 25px;
            width: 25px;
            opacity: 0.8;
        }
    }
    >.popUpWrapper {
        position: absolute;
        pointer-events: none;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 18px;
        padding: 15px 20px;
        width: 40vh;
        height: 28vh;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
        opacity: 0;
        transition: all 0.2s ease-in;
        >.popUp {
            width: 90%;
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            align-items: center;
            >.checkMark {
                margin-bottom: 35px;
                width: 100%;
                align-items: center;
                >img {
                    width: 30%;
                    margin: 0 auto;
                }
            }
            >p {
                color: #434343;
                font-weight: 600;
                margin-bottom: auto;
            }
        }
    }
    >.popUpWrapper.show {
        opacity: 1;
      }
    color: #fff;
    z-index: 100;
    position: fixed;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.9);
    backdrop-filter: blur(25px);
    transition: all 0.9s cubic-bezier(0.65, 0, 0.35, 1);
    font-family: 'Montserrat', sans-serif;
    >.formWrapper {
        z-index: 1000;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 50%;
        margin: auto;
        text-align: center;
        >.headWrapper {
            font-weight: 600;
            margin-bottom: 25px;
        }
        >form {
            >.symWrapper {
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                >div {
                    flex-grow: 1;
                    overflow: hidden;
                    margin-right: 5px;
                    margin-bottom: 9px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #141414;
                    background: #C7C7C7;
                    border-radius: 32.5px;
                    border: 0;
                    >label {
                        width: 100%;
                        display: flex;
                        cursor: none;
                        user-select: none;
                        border: 0;
                        >input {
                            border: 0;
                            position: absolute;
                            display: none;
                        }
                        >input:checked + span {
                            background-color: #1f1f1f;
                            color: #fff;
                        }
                        >span {
                            transition: all 0.6s;
                            padding: 8px 10px;
                            width: 100%;
                        }
                    }
                }
            }
            >.textWrapper {
                margin-bottom: 2px;
                >textarea {
                    overflow: auto;
                    outline: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;              
                    resize: none;
                    padding: 20px 40px;
                    width: 100%;
                    background: #C7C7C7;
                    border-radius: 32.5px;
                    border: 0;
                }
                >textarea::placeholder {
                    font-weight: 400;
                    color: #6D6D6D;
                }
            }
            >.nameWrapper {
                margin-bottom: 10px;
                >label { 
                    >input {
                        padding: 15px 40px;
                        width: 100%;
                        background: #C7C7C7;
                        border-radius: 32.5px;
                        border: 0;
                    }
                    >input::placeholder {
                        font-weight: 400;
                        color: #6D6D6D;
                    }
                }
            }
            >.phoneWrapper {
                margin-bottom: 10px;
                >label { 
                    >input {
                        padding: 15px 40px;
                        width: 100%;
                        background: #C7C7C7;
                        border-radius: 32.5px;
                        border: 0;
                    }
                    >input::placeholder {
                        font-weight: 400;
                        color: #6D6D6D;
                    }
                }
            }
            >.submitBtn {
                border-radius: 50px;
                background: #fff;
                color: #141414;
                border: 0;
                padding: 15px 40px;
            }
        }
    }
    @media (max-width: 767px) {
        >.formWrapper {
            height: 70vh;
            margin-top: 10px;
            width: 90%;
            >.headWrapper {
                margin-bottom: 10px;
                >h1 {
                    font-size: 26px;
                }
            }
            >form {
                >.symWrapper {
                    >div {
                        >label {
                            >span {
                                padding: 8px 5px;
                                font-size: 12px;
                            }
                        }
                    }
                }
                >.textWrapper {
                    height: 18vh;
                    margin-bottom: 4px;
                    >textarea {
                        height: 100%;
                        padding-top: 15px;
                        padding-left: 25px;
                        padding-bottom: 0;
                        font-size: 16px;
                    }
                }
                >.nameWrapper {
                    font-size: 16px;
                    margin-bottom: 4px;
                    >label {
                        >input {
                            padding-top: 15px;
                            padding-left: 25px;
                        }
                    }
                }
                >.phoneWrapper {
                    font-size: 16px;
                    margin-bottom: 4px;
                    >label {
                        >input {
                            padding-top: 15px;
                            padding-left: 25px;
                        }
                    }
                }
                >.emailWrapper {
                    font-size: 16px;
                    margin-bottom: 4px;
                    >label {
                        >input {
                            padding-top: 15px;
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }
}